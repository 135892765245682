<template>
  <v-container fluid class="bg-image">
    <v-btn color="error" class="logout-btn" @click.prevent="logout()">
      <v-progress-circular
        v-if="loadingLogout"
        indeterminate
        size="25"
        width="3"
        color="white"
      />
      <div v-else>
        <v-icon class="mr-2" color="white"> mdi-logout-variant </v-icon>
        Sair
      </div>
    </v-btn>

    <v-row justify="center" align="center" class="full-height mt-3">
      <v-col cols="10">
        <v-card class="profile-box py-8" elevation="2">
          <v-row v-if="!loading" justify="center" align="center">
            <v-col
              lg="3"
              md="4"
              sm="12"
              cols="12"
              class="d-flex justify-center"
            >
              <div v-if="!profile.photo && !imagePreviewUrl">
                <label for="avatar-input" class="profile_image_container">
                  <div class="profile_upload_placeholder">
                    <v-icon class="person_icon" size="40" color="#979797">
                      mdi-account
                    </v-icon>
                    <p>Selecione uma foto de perfil</p>
                  </div>
                </label>
                <input
                  id="avatar-input"
                  type="file"
                  style="display: none"
                  @change="handleImageChange"
                />
              </div>

              <div v-if="imagePreviewUrl" class="image_preview_container">
                <img :src="imagePreviewUrl" />
                <v-btn
                  class="cancel_btn"
                  color="light"
                  fab
                  x-small
                  @click="handleImageCancel"
                >
                  <v-icon size="20" color="#303030">mdi-close</v-icon>
                </v-btn>

                <v-btn
                  class="upload_btn"
                  color="primary"
                  small
                  :disabled="savingImage"
                  @click="handleImageSubmit"
                >
                  <v-progress-circular
                    v-if="savingImage"
                    indeterminate
                    size="25"
                    width="3"
                    color="white"
                  />
                  <span v-else class="white--text">Salvar</span>
                </v-btn>
              </div>

              <div v-if="profile.photo" class="image_preview_container">
                <img :src="profile.photo" alt="" />
                <v-btn
                  class="cancel_btn"
                  color="light"
                  fab
                  x-small
                  @click="removeImage"
                >
                  <v-icon size="20" color="#303030">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-col>

            <v-col lg="8" md="8" sm="12" cols="12">
              <v-form
                ref="form"
                v-model="valid"
                class="profile-form mr-5"
                lazy-validation
                @submit.prevent="updateProfile()"
              >
                <v-row justify="center" align="center" no-gutters>
                  <v-col cols="12" sm="12" class="mb-5">
                    <h2>Dados de perfil</h2>
                  </v-col>

                  <v-col lg="6" md="6" sm="12" cols="12" class="px-2">
                    <v-text-field
                      v-model="profile.full_name"
                      :rules="[rules.required]"
                      label="Nome completo"
                      rounded
                      outlined
                      required
                    />
                  </v-col>

                  <v-col lg="6" md="6" sm="12" cols="12" class="px-2">
                    <v-text-field
                      v-model="profile.username"
                      label="Nome de usuário"
                      disabled
                      rounded
                      outlined
                      required
                    />
                  </v-col>

                  <v-col lg="6" md="6" sm="12" cols="12" class="px-2">
                    <v-text-field
                      v-model="profile.email"
                      :rules="[rules.email]"
                      label="E-mail"
                      disabled
                      rounded
                      outlined
                      required
                    />
                  </v-col>

                  <v-col lg="6" md="6" sm="12" cols="12" class="px-2">
                    <v-text-field
                      v-model="profile.cpf"
                      :rules="[rules.required, rules.cpf]"
                      label="CPF"
                      v-mask="'###.###.###-##'"
                      rounded
                      outlined
                      required
                    />
                  </v-col>

                  <v-col lg="6" md="6" sm="12" cols="12" class="px-2">
                    <v-text-field
                      v-model="profile.phone_number"
                      :rules="[rules.required, rules.phone]"
                      label="Número de telefone"
                      v-mask="'(##) #####-####'"
                      rounded
                      outlined
                      required
                    />
                  </v-col>

                  <v-col lg="6" md="6" sm="12" cols="12" class="px-2">
                    <v-select
                      v-model="profile.gender"
                      :rules="[rules.required]"
                      :items="genders"
                      item-text="text"
                      item-value="value"
                      label="Gênero"
                      rounded
                      outlined
                      required
                    />
                  </v-col>

                  <v-col lg="4" sm="8" cols="8">
                    <v-btn
                      block
                      :disabled="!valid || savingProfileData"
                      color="primary"
                      class="profile-btn"
                      large
                      rounded
                      type="submit"
                      @click="updateProfile()"
                    >
                      <v-progress-circular
                        v-if="savingProfileData"
                        indeterminate
                        size="25"
                        width="3"
                        color="white"
                      />
                      <span v-else class="white--text">Salvar</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  data() {
    return {
      loading: true,
      loadingLogout: false,
      savingImage: false,
      savingProfileData: false,
      valid: true,
      user: {},
      genders: [
        {
          value: "masculino",
          text: "Masculino",
        },
        {
          value: "feminino",
          text: "Feminino",
        },
        {
          value: "nao_informar",
          text: "Não informar",
        },
      ],
      profile: {
        username: "",
        email: "",
        full_name: "",
        phone_number: "",
        gender: "",
        cpf: "",
      },
      file: null,
      imagePreviewUrl: null,
      rules: {
        required: (value) => !!value || "Este campo é obrigatório.",
        email: (value) => /.+@.+\..+/.test(value) || "Informe um email válido.",
        cpf: (value) =>
          (value && value.length == 14 && this.validateCPF(value)) ||
          "Informe um CPF válido",
        phone: (value) =>
          (value && value.length == 15) ||
          "Informe um número de telefone válido",
      },
    };
  },
  mounted() {
    this.getUser();
  },
  computed: {
    isGoogleFramedSignIn: {
      get() {
        return this.$store.state.isGoogleFramedSignIn;
      },
      set(value) {
        this.$store.commit("SET_GOOGLE_FRAMED_SIGN_IN", value);
      },
    },
  },
  methods: {
    async getUser() {
      try {
        this.user = await Auth.currentAuthenticatedUser();
      } catch (error) {
        await this.logout();
        console.log(error);
        this.$router.push("/");
        return;
      }

      this.profile = this.getUserProfile(this.user);

      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();

      this.profile = {
        ...this.profile,
        jwtToken,
      };

      this.$store.commit("SET_USER", this.profile);

      this.loading = false;

      if (window !== window.parent) {
        this.iframeReturnUserData(this.profile);
        this.iframeClose();
      }

      if (this.isGoogleFramedSignIn) {
        this.isGoogleFramedSignIn = false;
        this.ls_setGoogleSuccess(true);
        setTimeout(() => {
          window.close();
        }, 1500);
      }
    },
    async removeImage() {
      await Auth.updateUserAttributes(this.user, {
        picture: "",
      });
      this.profile.photo = "";
    },
    handleImageChange(e) {
      e.preventDefault();

      let reader = new FileReader();
      let file = e.target.files[0];

      reader.onloadend = () => {
        this.file = file;
        this.imagePreviewUrl = reader.result;
      };

      reader.readAsDataURL(file);
    },
    handleImageCancel() {
      this.file = null;
      this.imagePreviewUrl = null;
    },
    handleImageSubmit(e) {
      e.preventDefault();
      this.savingImage = true;
      const { type } = this.file;

      this.$http
        .post("/image-upload", { imageType: type })
        .then((res) => {
          const { data } = res;
          if (data.putUrl) {
            this.uploadImage(data.putUrl, data.getUrl);
          } else {
            this.savingImage = false;
            this.$toast.error("Algo de errado aconteceu. Tente novamente.");
          }
        })
        .catch((err) => {
          this.savingImage = false;
          this.$toast.error(
            err.response.data.message ||
              "Algo de errado aconteceu. Tente novamente."
          );
        });
    },
    uploadImage(putUrl, getUrl) {
      this.$http
        .put(putUrl, this.file)
        .then(async () => {
          this.savingImage = false;
          this.$toast.success("Foto de perfil salva com sucesso.");
          await Auth.updateUserAttributes(this.user, {
            picture: getUrl,
          });
          this.profile.photo = getUrl;

          const userData = {
            ...this.user,
            attributes: {
              ...this.user.attributes,
              picture: getUrl,
            },
          };

          this.$store.commit("SET_USER", this.getUserProfile(userData));

          this.file = null;
          this.imagePreviewUrl = null;
        })
        .catch(() => {
          this.savingImage = false;
          this.$toast.error("Algo de errado aconteceu. Tente novamente.");
        });
    },
    async updateProfile() {
      if (this.$refs.form.validate()) {
        this.savingProfileData = true;
        const { attributes } = this.user;

        try {
          const { full_name, cpf, phone_number, gender } = this.profile;
          await Auth.updateUserAttributes(this.user, {
            name: full_name,
            phone_number: this.cleanPhoneNumber(phone_number),
            gender: gender,
            "custom:cpf": cpf,
          });

          const userData = await Auth.currentAuthenticatedUser();

          this.$store.commit("SET_USER", this.getUserProfile(userData));

          if (attributes.identities) {
            await this.updateDatabaseProfile(userData.attributes);
          } else {
            this.$toast.success("Dados de perfil atualizados com sucesso.");
            this.savingProfileData = false;
          }
        } catch (error) {
          this.$toast.error(
            "Algo de errado aconteceu ao atualizar os seus dados. Tente novamente."
          );
          this.savingProfileData = false;
        }
      }
    },
    async updateDatabaseProfile(attributes) {
      this.$http
        .put(`/users/${attributes.sub}`, attributes)
        .then(async () => {
          this.$toast.success("Dados de perfil atualizados com sucesso.");
          this.savingProfileData = false;
        })
        .catch(() => {
          this.$toast.error(
            "Algo de errado aconteceu ao atualizar os seus dados. Tente novamente."
          );
          this.savingProfileData = false;
        });
    },
    async logout() {
      this.ls_setGoogleSuccess(false);
      this.loadingLogout = true;
      await Auth.signOut();
      this.$store.commit("SET_USER", null);
      this.$store.commit("SET_GOOGLE_FRAMED_SIGN_IN", false);
      setTimeout(() => {
        this.loadingLogout = false;
        this.$router.push("/");
      }, 1000);
    },
  },
};
</script>

<style scoped>
.profile-box {
  background-color: #fff;
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
}

.profile_image_container {
  width: 180px;
  height: 180px;
  border-radius: 100%;
  background-color: rgb(245, 245, 230);
  position: relative;
  border: 1px solid #d6d6d6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile_upload_placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.profile_upload_placeholder p {
  text-align: center;
  font-size: 14px;
  width: 80%;
}

.profile-form {
  width: 100%;
}
.image_preview_container {
  width: 180px;
  height: 180px;
  border-radius: 100%;
  position: relative;
  border: 1px solid #d6d6d6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image_preview_container img {
  object-fit: contain;
  width: 90%;
  height: 90%;
  border-radius: 100%;
}

.cancel_btn {
  position: absolute;
  top: 5px;
  left: 15px;
}

.upload_btn {
  position: absolute;
  bottom: 0;
  right: 0;
}

.logout-btn {
  position: absolute;
  z-index: 10;
  top: 20px;
  right: 20px;
  font-weight: bold;
  letter-spacing: 0 !important;
}
</style>
